import React from "react";
import { useAppState } from "@state/state";
import { ContactForm, Modal } from "@ui";

const ContactModal = () => {
  const [{ contactPage }, dispatch] = useAppState();

  return (
    <Modal
      showing={contactPage.showingModal}
      setShowing={showingModal =>
        dispatch({ type: "setShowingModal", showingModal })
      }
    >
      <div className="bg-white p-6 md:px-16 md:py-12 max-w-mdLg">
        <ContactForm
          onCloseMessage={() =>
            dispatch({ type: "setShowingModal", showingModal: false })
          }
        />
      </div>
    </Modal>
  );
};

export default ContactModal;
