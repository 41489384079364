import React from "react";
import PropTypes from "prop-types";
import { useAppState } from "@state/state";
import { Button } from "@ui";

const ContactModalButton = ({ text }) => {
  const [, dispatch] = useAppState();

  return (
    <Button
      preset="light"
      text={text}
      onClick={() =>
        dispatch({
          type: "setShowingModal",
          showingModal: true,
        })
      }
    />
  );
};

ContactModalButton.propTypes = {
  text: PropTypes.string.isRequired,
};

export default ContactModalButton;
