import React from "react";
import { useContactPageData } from "@staticQueries";
import { DefaultLayout } from "@layout";
import {
  Section,
  Container,
  Text,
  RawText,
  RichText,
  Pattern,
  Image,
} from "@atoms";
import { StackedCard, CardGroup } from "@composites";
import { Link, ContactForm } from "@ui";
import ContactModal from "./ContactModal";
import ContactModalButton from "./ContactModalButton";

const ContactPage = pageProps => {
  const { hero, instructions, formCta, office, meta } = useContactPageData();

  return (
    <DefaultLayout {...pageProps} meta={meta}>
      {/* Needed to include the Contact Form high up in the markup so that
      Netlify's bots could find and read it, as this is how they determine
      which fields belong to a particular form */}
      <div style={{ display: "none" }}>
        <ContactForm />
      </div>

      <div className="relative overflow-hidden">
        <Pattern
          variant="alt"
          orientation="top-right"
          className="text-green-3 z-10"
          doAnimation
        />

        {/* Hero */}
        <Section className="relative bg-green-1">
          <Container>
            <div className="max-w-sm">
              <Text tag="label" className="block text-green-3 mb-5">
                {hero.subheading}
              </Text>

              <RawText
                items={hero.heading.raw}
                className="text-white mb-6 md:mb-8"
              />

              <RichText
                html={hero.description.html}
                className="text-gray-3 mb-6 md:mb-10"
              />

              <ContactModalButton text={hero.ctaText} />
            </div>
          </Container>
        </Section>

        {/* Instructions */}
        <Section className="relative bg-green-1" style={{ marginTop: -1 }}>
          <Container>
            <CardGroup
              gutter={6}
              cards={[
                ...instructions.map(({ heading, description }, idx) =>
                  React.useCallback(({ className }) => (
                    <StackedCard
                      className={className}
                      top={() => (
                        <div
                          className="font-mono text-green-2 -mb-3"
                          style={{ fontSize: 105 }}
                        >
                          {`${idx + 1}`.padStart(2, "0")}
                        </div>
                      )}
                      bottom={() => (
                        <>
                          <Text tag="label" className="text-green-3 mb-2">
                            {heading}
                          </Text>

                          <RichText
                            html={description.html}
                            className="text-white"
                          />
                        </>
                      )}
                    />
                  ))
                ),
                React.useCallback(({ className }) => (
                  <div className={className}>
                    <div className="bg-white h-full flex flex-col justify-center p-6 md:p-12 md:pt-10">
                      <Text tag="h2" className="text-green mb-2">
                        {formCta.heading}
                      </Text>

                      <Text
                        tag="p"
                        options={{ size: "text-sm" }}
                        className="text-green mb-6"
                      >
                        {formCta.description}
                      </Text>

                      <div>
                        <ContactModalButton text={formCta.ctaText} />
                      </div>
                    </div>
                  </div>
                )),
              ]}
            />
          </Container>
        </Section>

        {/* Office */}
        <Section
          className="relative bg-white"
          hasPaddingTop={false}
          hasPaddingBottom={false}
        >
          <div className="relative md:absolute w-full h-64 md:w-1/2 md:h-auto top-0 bottom-0 left-0">
            <Image image={{ ...office.map, fpx: "Center", fpy: "Center" }} />
          </div>

          <Container>
            <div className="w-full md:w-1/2 ml-auto py-8 md:pl-16 md:py-16 lg:pl-20 lg:py-20">
              <Text tag="label" className="text-green-3">
                {office.intro.subheading}
              </Text>
              <RawText
                items={office.intro.heading.raw}
                className="text-green-2 mt-4 mb-6 md:mb-8"
              />

              <div className="flex flex-col sm:flex-row mb-6">
                <div className="sm:w-1/2 sm:pr-4">
                  <Text tag="label" className="block text-green-3 mb-2">
                    Address:
                  </Text>
                  <Link
                    to={`https://maps.google.com/?q=${encodeURIComponent(
                      office.info.address
                    )}`}
                    target="_blank"
                    className="block"
                  >
                    <Text
                      tag="address"
                      use="p"
                      options={{ style: "not-italic" }}
                    >
                      {office.info.address}
                    </Text>
                  </Link>
                </div>

                <div className="sm:w-1/2 sm:pl-4">
                  <div className="my-2 sm:mt-0">
                    <Text tag="label" className="text-green-3">
                      Phone:
                    </Text>
                    <Link to={`tel:${office.info.phone}`} target="_blank">
                      <Text tag="p">{office.info.phone}</Text>
                    </Link>
                  </div>

                  <div>
                    <Text tag="label" className="text-green-3">
                      Email:
                    </Text>
                    <Link to={`mailto:${office.info.email}`} target="_blank">
                      <Text tag="p">{office.info.email}</Text>
                    </Link>
                  </div>
                </div>
              </div>

              {office.info.parking1 && (
                <div className="mb-6">
                  <Text tag="label" className="block text-green-3 mb-2">
                    Parking:
                  </Text>

                  <div className="flex">
                    <div className="w-1/2 pr-4">
                      <Text tag="p" className="whitespace-pre-wrap">
                        {office.info.parking1}
                      </Text>
                    </div>

                    {office.info.parking2 && (
                      <div className="w-1/2 pl-4">
                        <Text tag="p" className="whitespace-pre-wrap">
                          {office.info.parking2}
                        </Text>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {office.info.massTransit1 && (
                <div>
                  <Text tag="label" className="block text-green-3 mb-2">
                    Light Rail & Bus Transit:
                  </Text>

                  <div className="flex">
                    <div className="w-1/2 pr-4">
                      <Text tag="p" className="whitespace-pre-wrap">
                        {office.info.massTransit1}
                      </Text>
                    </div>

                    {office.info.massTransit2 && (
                      <div className="w-1/2 pl-4">
                        <Text tag="p" className="whitespace-pre-wrap">
                          {office.info.massTransit2}
                        </Text>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </Container>
        </Section>

        {/* Modal */}
        <ContactModal />
      </div>
    </DefaultLayout>
  );
};

export default ContactPage;
